<template>
  <div class="planning-month__staff-list">
    <template v-for="activity of activities">
      <div
        v-if="activity.staff.length"
        :key="activity.id"
        class="staff-info-table"
        :class="{
          'staff-info-table--collapsed': viewState[activity.id] === 'collapsed',
        }"
      >
        <div class="staff-info-table__title" @click="toggleView(activity.id)">
          {{ activity.role }}
        </div>
        <div class="staff-info-table__head columns-template">
          <div class="staff-info-table__head-column">Сотрудник</div>
          <div class="staff-info-table__head-column">Квалификация</div>
          <div class="staff-info-table__head-column">En level</div>
          <div class="staff-info-table__head-column">
            Кол-во производ. проектов
          </div>
          <div class="staff-info-table__head-column">Доступность</div>
          <div class="staff-info-table__head-column">Запланировано (всего)</div>
          <div class="staff-info-table__head-column">Расхождение</div>
          <div class="staff-info-table__head-column">Комментарий</div>
        </div>
        <div class="staff-info-table__body">
          <div
            v-for="staff of activity.staff"
            :key="staff.id"
            class="staff-info-table__body-item columns-template"
          >
            <div class="staff-info-table__body-column">
              {{ fullName(staff) }}
            </div>
            <div class="staff-info-table__body-column">
              {{ staff.qualification | capitalize }}
            </div>
            <div class="staff-info-table__body-column">
              {{ EN_LEVELS[staff.english_level] }}
            </div>
            <div class="staff-info-table__body-column">
              <span class="column__value">{{ staff.total_projects }}</span>
              <span
                class="column__attention-bg"
                :class="{
                  'column__attention-bg--worry': staff.total_projects > 2,
                  'column__attention-bg--oversee': staff.total_projects === 2,
                }"
              ></span>
            </div>
            <div class="staff-info-table__body-column">
              {{ getAvailableHours(staff) }}
            </div>
            <div class="staff-info-table__body-column">
              {{ staff.plan_hours }}
            </div>
            <div class="staff-info-table__body-column">
              <span class="column__value">{{ staff.dif }}</span>
              <span
                class="column__attention-bg"
                :class="{
                  'column__attention-bg--ok': staff.dif <= 5 && staff.dif >= -5,
                  'column__attention-bg--worry': staff.dif > 5,
                  'column__attention-bg--oversee': staff.dif < -5,
                }"
              ></span>
            </div>
            <StaffTableCommentCell
              :data="staff.employee_month[0]"
              @save="updateComment"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EN_LEVELS } from "@/assets/enums";

import StaffTableCommentCell from "@/components/StaffTableCommentCell.vue";

export default {
  name: "StaffInfoTable",
  components: {
    StaffTableCommentCell,
  },
  props: {
    activities: {
      type: Array,
      default() {
        return [];
      },
    },
    isEditable: {
      type: Boolean,
    },
  },
  data() {
    return {
      EN_LEVELS,
      viewState: {},
    };
  },
  watch: {
    activities() {
      this.activities.forEach((activity) => {
        this.$set(this.viewState, activity.id, "open");
      });
    },
  },
  computed: {
    ...mapGetters({
      fullName: "staffStore/fullName",
    }),
  },
  methods: {
    ...mapActions({
      changeStaffActivity: "planningsStore/changeStaffActivity",
    }),
    ...mapMutations({
      setLastUpdate: "planningsStore/setLastUpdate",
    }),
    toggleView(id) {
      this.viewState[id] =
        this.viewState[id] === "collapsed" ? "open" : "collapsed";
    },
    getAvailableHours(staff) {
      return staff.employee_month[0]?.available_hours ?? "-";
    },
    async updateComment({ id, comment }) {
      await this.changeStaffActivity({ id, comment });
      this.setLastUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.staff-info-table {
  display: grid;
  grid-template-rows: 30px auto auto;
  width: min-content;
  padding: 0 10px;
  border-radius: 7px;
  background: linear-gradient($erp-primary-bg-color 35px, #f3f3f3 35px);
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  user-select: none;
  font-size: 12px;
}

.staff-info-table:not(:last-child) {
  margin-bottom: 20px;
}

.staff-info-table__title {
  background-color: $erp-primary-bg-color;
  color: #f3f3f3;
  height: 30px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .staff-info-table--collapsed & {
    width: 960px;
  }
}

.columns-template {
  display: grid;
  grid-template-columns: 150px 115px 70px 70px 100px 120px 115px 220px;
}

.staff-info-table__head {
  background-color: #f3f3f3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 5px;
  font-weight: 700;
  .staff-info-table--collapsed & {
    display: none;
  }
}

.staff-info-table__head-column {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.staff-info-table__head-column:not(:last-child) {
  border-right: 1px solid #808080;
}

.staff-info-table__body {
  background-color: #f3f3f3;
  .staff-info-table--collapsed & {
    display: none;
  }
}

.staff-info-table__body-item {
  padding: 7px 0;
  border-radius: 4px;
}

.staff-info-table__body-item:not(:last-child) {
  border-bottom: 1px dashed #d3d3d3;
}

.staff-info-table__body-column {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.staff-info-table__input {
  width: 40%;
  border: none;
  text-align: center;
  background-color: inherit;
  font-family: inherit;
  margin: 0 auto;
  font-weight: inherit;
}

.staff-info-table__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.column__value {
  z-index: 1;
}

.column__attention-bg {
  position: absolute;
  top: -7px;
  bottom: -7px;
  width: 100%;
  z-index: 0;
  color: #ffffff;
  border-radius: 3px;
}

.column__attention-bg--ok {
  background-color: #dcf7c7;
}

.column__attention-bg--worry {
  background-color: #f9b398;
}

.column__attention-bg--oversee {
  background-color: #f4d3a2;
}

.staff-info-table__body-column:not(:first-child) {
  justify-content: center;
}
</style>
