<template>
  <div class="container">
    <!--    TODO: currentMonthNumb не сокращаем название. Полностью допиши,-->
    <!--    пожалуйста, currentMonthNumber-->
    <h2>
      Планирование на {{ MONTHS[currentMonthNumb - 1] }} {{ currentYear }}
    </h2>
    <DateSelect
      mode="info"
      :monthInfo="monthInfo"
      :selectedDate="currentYear + '-' + currentMonthNumb"
    />
    <div class="monthly-planning-workplace">
      <div class="monthly-planning-workplace__side">
        <div class="monthly-planning-workplace__filters">
          <CheckboxFilterModal
            title="Тип производства"
            :list="PROJECTS_TYPES.production"
            :selectedValues="activeProjectsTypes.production"
            @setValue="
              (selectedTypes) =>
                setActiveProjectsTypes(selectedTypes, 'production')
            "
          />
          <CheckboxFilterModal
            title="Тип коммерции"
            :list="PROJECTS_TYPES.commercial"
            :selectedValues="activeProjectsTypes.commercial"
            @setValue="
              (selectedTypes) =>
                setActiveProjectsTypes(selectedTypes, 'commercial')
            "
          />
        </div>
        <PlanningTableItem
          v-for="project of planningProjectsList"
          :key="project.id"
          :project="project"
          :monthId="currentMonthId"
          :employeesInfo="employeesInfo"
          :isEditable="isEditable"
          :updateTime="updateTime"
        />
      </div>
      <div class="monthly-planning-workplace__side">
        <div class="monthly-planning-workplace__filters">
          <CheckboxFilterModal
            title="Направления"
            :list="positionsList"
            :all-set-at-initialization="true"
            @setValue="setPosition"
          />
        </div>
        <StaffInfoTable
          :activities="staffActivities"
          :isEditable="isEditable"
        />
      </div>
    </div>
    <ChangePlanningModal />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { PROJECTS_TYPES, MONTHS } from "@/assets/constants";
import DateSelect from "@/components/DateSelect";
import CheckboxFilterModal from "@/components/CheckboxFilterModal";
import PlanningTableItem from "@/components/PlanningTableItem";
import StaffInfoTable from "@/components/StaffInfoTable.vue";
import ChangePlanningModal from "@/components/modal/ChangePlanningModal.vue";
import dayjs from "dayjs";

export default {
  name: "monthly-planning",
  components: {
    ChangePlanningModal,
    DateSelect,
    PlanningTableItem,
    StaffInfoTable,
    CheckboxFilterModal,
  },
  data() {
    return {
      PROJECTS_TYPES,
      MONTHS,
      currentYear: parseInt(this.$route.params["year"]),
      currentMonthNumb: parseInt(this.$route.params["month"]),
      currentMonthId: null,
      monthInfo: null,
      projectsList: [],
      displayData: [],
      updateTime: Date.now(),
    };
  },
  computed: {
    ...mapState({
      timeZones: (state) => state.timeZones,
      productionCalendar: (state) =>
        state.productionCalendarStore.productionCalendar,
      serverTime: (state) => state.productionCalendarStore.serverTime,
      lastUpdate: (state) => state.planningsStore.lastUpdate,
      planningsList: (state) => state.planningsStore.planningsList,
      planningProjectsList: (state) =>
        state.planningsStore.planningProjectsList,
      staffActivities: (state) => state.planningsStore.staffActivities,
      activitiesList: (state) => state.planningsStore.activitiesList,
      activeProjectsTypes: (state) => state.planningsStore.activeProjectsTypes,
    }),
    isEditable() {
      const { year, month } = this.$route.params;
      if (!year && !month) {
        return false;
      }
      const editedDate = dayjs
        .utc(`${year}-${month}`)
        .endOf("months")
        .add(1, "day");
      const systemDate = dayjs.utc(this.serverTime);
      return editedDate >= systemDate;
    },
    positionsList() {
      return this.activitiesList.map((item) => ({
        name: item.activity,
        id: item.id,
      }));
    },
    employeesInfo() {
      const result = {};
      this.staffActivities.forEach((activity) => {
        activity.staff.forEach((staff) => {
          result[staff.id] = {
            dif: staff.dif,
            projectsCount: staff.total_projects,
          };
        });
      });
      return result;
    },
  },
  watch: {
    async lastUpdate() {
      await this.loadStaffActivities();
    },
  },
  async created() {
    this.restoreFilters();
    await this.loadPlanningsList();
    this.setCurrentMonthId();
    await this.loadStaffList({ limit: 999 });
    if (this.currentMonthId !== null) {
      await this.loadServerTime();
      this.setMonthId(this.currentMonthId);
      await this.loadActivitiesList();
      // информация о количестве рабочих и сокращенных дней в выбранном месяце
      // подгружаем только если еще не грузили для данного id
      if (!this.productionCalendar[this.currentMonthId]) {
        await this.loadProductionCalendar({ id: this.currentMonthId });
      }
      this.monthInfo = this.productionCalendar[this.currentMonthId] || null;
      // информация о проектах, имеющих статус "старт", "разработка" или "завершение" для выбранного месяца
      await this.loadPlanningProjectsList();
      await this.loadStaffActivities();
      this.updateTime = Date.now();
    }
  },
  destroyed() {
    this.clearStaffActivities();
    this.clearPositionFilter();
  },
  methods: {
    ...mapActions({
      loadPlanningsList: "planningsStore/loadPlanningsList",
      loadPlanningProjectsList: "planningsStore/loadPlanningProjectsList",
      loadStaffActivities: "planningsStore/loadStaffActivities",
      loadProductionCalendar: "productionCalendarStore/loadProductionCalendar",
      loadServerTime: "productionCalendarStore/loadServerTime",
      loadStaffList: "staffStore/loadStaffList",
      loadTimeZones: "loadTimeZones",
      loadActivitiesList: "planningsStore/loadActivitiesList",
      setPositionFilter: "planningsStore/setPositionFilter",
      setProjectsTypes: "planningsStore/setProjectsTypes",
      restoreFilters: "planningsStore/restoreFilters",
    }),
    ...mapMutations({
      clearStaffActivities: "planningsStore/clearStaffActivities",
      setMonthId: "planningsStore/setMonthId",
      clearPositionFilter: "planningsStore/clearPositionFilter",
    }),
    getMonthId(monthNumb, year) {
      const month = this.planningsList.find(
        (month) => month.name === monthNumb && month.year.number === year
      );
      return month?.id || null;
    },
    setCurrentMonthId() {
      this.currentMonthId = this.getMonthId(
        this.currentMonthNumb,
        this.currentYear
      );
    },
    async setActiveProjectsTypes(selectedProjectsTypes, typeName) {
      await this.setProjectsTypes({ selectedProjectsTypes, typeName });
      this.updateTime = Date.now();
    },
    async setPosition(positionIds) {
      await this.setPositionFilter(positionIds);
    },
  },
};
</script>

<style lang="scss" scoped>
.monthly-planning-workplace {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 30px;
}

.monthly-planning-workplace__filters {
  display: flex;
  align-self: start;
  column-gap: 25px;
  margin-bottom: 25px;
}
</style>
