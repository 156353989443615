<template>
  <TooltipCommon
    :delay="150"
    :distance="6"
    :show-tooltip="hasTooltipMessage"
    class="comment-container"
    @click.native="isActive = true"
  >
    <template v-slot:message>
      <p class="comment-cell__tooltip-message">{{ localComment }}</p>
    </template>
    <input
      v-show="isActive"
      v-model="localComment"
      ref="inputRef"
      @blur="isActive = false"
      @keypress.enter="(event) => event.target.blur()"
    />
    <span v-show="!isActive" ref="text" class="comment-cell__text">
      {{ localComment }}
    </span>
  </TooltipCommon>
</template>

<script>
import { clamp } from "@/assets/utils";
import TooltipCommon from "@/components/TooltipCommon.vue";

export default {
  name: "StaffTableCommentCell",
  components: {
    TooltipCommon,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      isActive: false,
      hasTooltipMessage: false,
      localComment: "",
      clamp: clamp(),
    };
  },
  watch: {
    data: {
      handler(value) {
        if (!value) {
          return;
        }
        this.localComment = value.comment;
        this.prepareText();
      },
      deep: true,
      immediate: true,
    },
    isActive(isTrue) {
      if (isTrue) {
        this.$nextTick(() => {
          this.$refs.inputRef?.focus();
          this.hasTooltipMessage = false;
        });
      } else {
        this.prepareText();
        this.save();
      }
    },
    localComment(comment) {
      if (comment.length > 200) {
        this.$pushMessage({
          message: "Достигнута максимальная длина комментария (200 символов).",
          title: "Комментарий",
          withoutDelay: true,
        });
      }
      this.localComment = comment.slice(0, 200);
    },
  },
  methods: {
    save() {
      const comment = this.localComment.trim();
      if (this.data.comment.trim() !== comment) {
        this.$emit("save", {
          id: this.data.id,
          comment,
        });
      }
    },
    prepareText() {
      this.$nextTick(async () => {
        const isChanged = await this.clamp(this.$refs.text, this.localComment);
        this.hasTooltipMessage = isChanged;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-container {
  position: relative;
  margin: -7px 0;
  padding: 0 10px;
  height: 33px;
}

.comment-cell__tooltip-message {
  background-color: #282828;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px;
  word-wrap: break-word;
}

.comment-cell__text {
  max-width: 100%;
  word-wrap: break-word;
}
</style>
