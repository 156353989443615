<template>
  <div class="project-table" :class="{ 'project-table--collapsed': !isOpen }">
    <div class="project-table__title" @click="toggleView">
      <div class="project-table__name">{{ title }}</div>
      <div v-if="projectStack" class="project-table__stack">
        {{ projectStack }}
      </div>
    </div>
    <div class="project-table__head">
      <div class="project-table__head-column">Направление</div>
      <div class="project-table__head-column">Планируемое (всего)</div>
      <div
          class="project-table__head-column project-table__head-column--with-subcolumns"
      >
        <div class="project-table__head-column-title">Прошлый месяц</div>
        <div class="project-table__head-column-subcolumns">
          <div class="project-table__head-column-subtitle">План</div>
          <div class="project-table__head-column-subtitle">Факт</div>
        </div>
      </div>
      <div class="project-table__head-column">Планируемое</div>
      <div class="project-table__head-column">Сотрудник</div>
    </div>
    <div class="project-table__body">
      <div
          v-for="direction of directions"
          :key="direction.activity.id + direction.update"
          class="project-table__body-direction"
      >
        <div class="project-table__body-direction-column direction-name">
          <Select
              :isReady="direction.isReady && directionsNames.length !== 0"
              :default="direction.activity.activity || 'Не выбрано'"
              :context="{ lastDirectionId: direction.id }"
              :options="directionsNames"
              :disabled="!isEditable"
              :mode="['table']"
              showModel="activity"
              @selectOption="selectDirectionName"
          />
          <div class="project-table__actions">
            <Button
                theme="icon"
                :disabled="!isEditable"
                @click.native="createEmployee(direction.id)"
                class="project-table__action project-table__action--employee"
            >
              <AddEmployee/>
            </Button>

            <Button
                v-show="direction.isReady"
                theme="icon"
                :disabled="!isEditable"
                @click.native="removeDirection(direction.id)"
                class="project-table__action"
            >
              <Trash/>
            </Button>

            <Loading v-show="!direction.isReady"/>
          </div>
        </div>
        <div class="project-table__body-direction-column">
          {{ direction.total_plan_hours | noData }}
        </div>
        <div class="project-table__body-subdirections">
          <div
              v-for="(employee, index) of direction.employees"
              :key="getEmployeeName(employee) + employee.id + employee.update"
              class="project-table__body-subdirection"
              :class="{
              'project-table__body-subdirection--lead':
                employee.profile.id === direction.leadId,
            }"
          >
            <div
                class="project-table__body-subdirection-subcolumns project-table__body-direction-column"
            >
              <div class="project-table__body-subdirection-subcolumns-item">
                {{ employee.total_plan_hours_past | noData }}
              </div>
              <div class="project-table__body-subdirection-subcolumns-item">
                {{ employee.total_actual_hours_past | noData }}
              </div>
            </div>
            <div class="project-table__body-direction-column">
              <TooltipCommon
                  v-if="employeesInfo[employee.profile.id]"
                  :delay="500"
                  position="top"
                  class="project-table__tooltip"
                  @click.native="focusInput('input-' + employee.id)"
              >
                <template v-slot:message>
                  <div class="project-table__tooltip-message">
                    <div
                        class="project-table__tooltip-message-item"
                        :class="{
                        'attention-bg--oversee':
                          employeesInfo[employee.profile.id].projectsCount ===
                          2,
                        'attention-bg--worry':
                          employeesInfo[employee.profile.id].projectsCount > 2,
                      }"
                    >
                      П:
                      {{ employeesInfo[employee.profile.id].projectsCount }}
                    </div>
                    <div
                        class="project-table__tooltip-message-item"
                        :class="{
                        'attention-bg--ok':
                          employeesInfo[employee.profile.id].dif <= 5 &&
                          employeesInfo[employee.profile.id].dif >= -5,
                        'attention-bg--worry':
                          employeesInfo[employee.profile.id].dif > 5,
                        'attention-bg--oversee':
                          employeesInfo[employee.profile.id].dif < -5,
                      }"
                    >
                      Р: {{ employeesInfo[employee.profile.id].dif }}
                    </div>
                  </div>
                </template>
                <input
                    :ref="'input-' + employee.id"
                    class="project-table__input"
                    :class="{
                    'project-table__input--ok':
                      employee.plan_hours.resultServerRequest === 'ok',
                    'project-table__input--error':
                      employee.plan_hours.resultServerRequest === 'error',
                  }"
                    type="number"
                    maxlength="2"
                    :value="employee.plan_hours.value | noData"
                    :disabled="!isEditable"
                    @input="inputValidation"
                    @keypress.enter="setPlanHours($event, direction.id, index)"
                    @blur="setPlanHours($event, direction.id, index)"
                />
              </TooltipCommon>
            </div>
            <div
                class="project-table__body-direction-column subdirection-employee"
            >
              <RadioButton
                  :isLead="employee.profile.id === direction.leadId"
                  :disabled="!isEditable"
                  class="project-table__radio"
                  @clickRadio="
                  checkLead(direction.id, employee.profile.id);
                  setLead(direction.id, index, employee.id);
                  checkInfo(employee.id);
                "
              />
              <Select
                  :mode="['table', 'with-search']"
                  :default="getEmployeeName(employee.profile) || 'Не выбрано'"
                  :context="{
                  direction,
                  employeeIdx: index,
                }"
                  :options="getEmployeesList(direction.activity.id)"
                  :showModel="getEmployeeName"
                  :disabled="!isEditable"
                  @selectOption="selectEmployee"
                  @searchValue="setSearchName"
                  @click.native="employeeSelectInfo(direction)"
                  @contextmenu="(event) => openContextMenu(event, employee)"
              />
              <Loading v-show="!isShowButton(direction, index)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="project-table__footer">
        <div
            class="project-table__footer-create"
            :class="{
            'project-table__footer-create--disabled':
              isDisabledAddDirButton || !isEditable,
          }"
            @click="createDirection"
        >
          <Plus class="button-icon button-icon--plus"/>
          добавить направление
        </div>
        <div class="project-table__table__footer-info">
          Запланировано на проект: {{ totalProjectTime }}
        </div>
      </div>
    </div>
    <ContextMenuModal ref="contextMenuModalRef"/>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import {isNameMatch} from "@/assets/utils";
import {EN_LEVELS} from "@/assets/enums";
import api from "@/api";

import Button from "@/components/Button.vue";
import RadioButton from "@/components/RadioButton.vue";
import Select from "@/components/Select.vue";
import Loading from "@/components/Loading.vue";
import TooltipCommon from "@/components/TooltipCommon.vue";

import Plus from "@/assets/img/plus.svg?inline";
import Trash from "@/assets/img/trash.svg?inline";
import AddEmployee from "@/assets/img/add-employee.svg?inline";
import ContextMenuModal from "@/components/modal/ContextMenuModal";

const isFake = (value) => {
  return (
      value === null ||
      value === undefined ||
      value.toString().startsWith("fake-")
  );
};

export default {
  name: "PlanningTableItem",
  components: {
    ContextMenuModal,
    Button,
    RadioButton,
    Select,
    Loading,
    Plus,
    Trash,
    AddEmployee,
    TooltipCommon,
  },
  props: {
    project: {
      type: Object,
    },
    monthId: {
      type: Number,
    },
    employeesInfo: {
      type: Object,
      default: () => {
      },
    },
    isEditable: {
      type: Boolean,
    },
    updateTime: {
      type: Number,
    },
  },
  data() {
    return {
      projectStack: "",
      directions: [],
      directionsNames: [],
      selectedDirectionsIds: [],
      employeesList: {},
      selectedEmployees: {},
      searchName: "",
      totalProjectTime: 0,
      isOpen: true,
    };
  },
  computed: {
    ...mapState({
      staffList: (state) => state.staffStore.staffList,
    }),
    ...mapGetters({
      getCurrentEmployeeMonthsPlanningsListId:
          "planningsStore/getCurrentEmployeeMonthsPlanningsListId",
      getIsChangeEmployeeMonthsPlanningsListModeAdd:
          "planningsStore/getIsChangeEmployeeMonthsPlanningsListModeAdd",
      getLastUpdate: "planningsStore/getLastUpdate",
    }),
    title() {
      return `${this.project.customer?.name} - ${
          this.project.name
      } (${this.project.status.toLowerCase()})`;
    },
    isDisabledAddDirButton() {
      let noSelectDirLength = 0;
      const freeDirLength = this.directionsNames.length;
      this.directions.forEach((dir) => {
        if (isFake(dir.id)) {
          noSelectDirLength++;
        }
      });
      return !freeDirLength || noSelectDirLength >= freeDirLength;
    },
  },
  watch: {
    async updateTime() {
      await this.getDirectionsNamesDB();
      this.presetProjectDirections();
      this.presetStaffList();
      this.setProjectStack();
      this.sortDirections();
      this.setTotalProjectTime();
    },
    getLastUpdate(lastUpdateTime) {
      if (lastUpdateTime && this.getCurrentEmployeeMonthsPlanningsListId) {
        if (!this.getIsChangeEmployeeMonthsPlanningsListModeAdd) {
          let currentEmployee = null;
          const isCurrentPlanningTable = this.directions.find((direction) => {
            currentEmployee = direction.employees.find((employee) => {
              return (
                  employee.id === this.getCurrentEmployeeMonthsPlanningsListId
              );
            });
            return currentEmployee;
          });

          if (isCurrentPlanningTable) {
            this.removeEmployee(isCurrentPlanningTable.id, currentEmployee);
            this.setCurrentEmployeeMonthsPlanningsListId(0);
          }
        } else {
          this.setCurrentEmployeeMonthsPlanningsListId(0);
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      setLastUpdate: "planningsStore/setLastUpdate",
      setIsChangeEmployeeMonthsPlanningsListModeAdd:
          "planningsStore/setIsChangeEmployeeMonthsPlanningsListModeAdd",
      setCurrentEmployeeMonthsPlanningsListId:
          "planningsStore/setCurrentEmployeeMonthsPlanningsListId",
    }),
    ...mapActions({
      checkAvailableEmployeeMonthsPlanningsList:
          "planningsStore/checkAvailableEmployeeMonthsPlanningsList",
    }),
    openContextMenu(event, employee) {
      event.preventDefault();
      if (!this.isEditable) {
        return;
      }
      this.$refs.contextMenuModalRef.setPosition({
        top: event.clientY,
        left: event.clientX,
      });

      const isDisabled =
        !employee.profile.first_name || !employee.profile.last_name;

      const addOrUpdateAction = async () => {
        console.log(employee);
        this.setIsChangeEmployeeMonthsPlanningsListModeAdd(true);
        this.setCurrentEmployeeMonthsPlanningsListId(employee.id);
        await this.checkAvailableEmployeeMonthsPlanningsList({
          sub_assignment_id: employee.id,
          isDeletion: false,
          currentMonthId: this.monthId,
        });
      };

      const deleteAction = async () => {
        if (isDisabled) {
          const currentDirection = this.directions.find((direction) => {
            return direction.employees.find((empl) => {
              return empl.id === employee.id;
            });
          });

          await this.removeEmployee(currentDirection.id, employee);
        } else {
          this.setIsChangeEmployeeMonthsPlanningsListModeAdd(false);
          this.setCurrentEmployeeMonthsPlanningsListId(employee.id);
          await this.checkAvailableEmployeeMonthsPlanningsList({
            sub_assignment_id: employee.id,
            isDeletion: true,
            currentMonthId: this.monthId,
          });
        }
      };

      const modalContent = [
        {
          title: "добавить/обновить",
          action: addOrUpdateAction,
          isDisabled,
        },
        {
          title: "удалить",
          action: deleteAction,
        },
      ];
      this.$refs.contextMenuModalRef.setModalContent(modalContent);
      this.$refs.contextMenuModalRef.open();
    },
    toggleView() {
      this.isOpen = !this.isOpen;
    },
    setSearchName(value) {
      this.searchName = value;
    },
    isShowButton(direction, employeeIndex) {
      const employee = direction.employees[employeeIndex];
      if (!employee?.isReady || !direction?.isReady) {
        return false;
      } else {
        return true;
      }
    },
    getEmployeesList(directionId) {
      let employeesList = null;
      const searchName = this.searchName;
      if (searchName.length > 2) {
        employeesList = this.staffList.filter((staff) => {
          const {first_name, last_name} = staff;
          return isNameMatch({
            first_name,
            last_name,
            input_text: searchName,
          });
        });
      } else {
        employeesList = this.employeesList[directionId];
      }
      const selectedEmployeesIds = this.selectedEmployees[directionId];
      if (!employeesList) {
        return;
      }
      return employeesList.filter((employee) => {
        return !selectedEmployeesIds.includes(employee.id);
      });
    },
    directionPrototype() {
      const id = Date.now().toString();
      return {
        isReady: true,
        id: "fake-" + id,
        leadId: null,
        activity: {
          activity: null,
          id: "fake-" + id,
          role_sort: id,
        },
        total_plan_hours: null,
        update: id,
        employees: [this.employeePrototype()],
      };
    },
    employeePrototype(subdirection, employee) {
      const id = Date.now().toString();
      return {
        id: subdirection?.id || "fake-" + id,
        lead: subdirection?.lead || false,
        profile: {
          id: employee?.id || "fake-" + id,
          first_name: employee?.first_name || null,
          last_name: employee?.last_name || null,
          qualification: employee?.qualification || null,
        },
        plan_hours: {
          value: subdirection?.plan_hours || null,
          isReady: true,
          resultServerRequest: null,
        },
        total_plan_hours_past: subdirection?.total_plan_hours_past || null,
        total_actual_hours_past: subdirection?.total_actual_hours_past || null,
        isReady: true,
        update: id,
      };
    },
    async presetProjectDirections() {
      this.project.assignments.forEach((direction) => {
        this.setDirection(direction);
        this.sortEmployees(direction.id);
        this.setSelectedEmployeesId(direction);
        this.selectedDirectionsIds.push(direction.activity.id);
      });
    },
    async getDirectionsNamesDB() {
      try {
        const response = await api.plannings.getProjectActivities(
            this.project.id,
            this.monthId
        );

        const directions = response.results.filter((directionName) => {
          return !this.selectedDirectionsIds.includes(directionName.id);
        });
        this.directionsNames = directions;
        return true;
      } catch (e) {
        console.error("getDirectionsNamesDB", e);
        this.$pushMessage(
            {
              title: `${e.code} Получение списка направлений деятельности на проекте`,
              message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
            },
            {root: true}
        );
        return false;
      }
    },
    async setStaffListForDirection(directionId) {
      try {
        if (isFake(directionId)) {
          return;
        }
        const staffListForDirection = this.staffList.filter(
            (staff) => staff.position.id === directionId
        );
        this.$set(this.employeesList, directionId, staffListForDirection);
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    presetStaffList() {
      for (const direction of this.directions) {
        this.setStaffListForDirection(direction.activity.id);
      }
    },
    async getDirectionInfoDB(directionId) {
      try {
        const projInfo = await api.plannings.getProjectInfo(
            this.project.id,
            this.monthId
        );

        const projDirections = projInfo.assignments;

        const selectedDirection = projDirections.find(
            (dir) => dir.activity.id === directionId
        );

        return selectedDirection;
      } catch (e) {
        console.error(e);
        this.$pushMessage(
            {
              title: `${e.code} Получение данных по одному проекту`,
              message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
            },
            {root: true}
        );
        return false;
      }
    },
    async setDirectionDB(directionId) {
      try {
        await api.plannings.createActivity(
            this.project.id,
            this.monthId,
            directionId
        );
        return true;
      } catch (e) {
        console.error("setDirectionDB", e);
        this.$pushMessage(
            {
              title: `${e.code} Создание направления на проекте`,
              message: `Ошибка сети. Не удалось создать направление.\n${e.message}`,
            },
            {root: true}
        );
        return false;
      }
    },
    async deleteDirectionDB(directionId) {
      try {
        if (!isFake(directionId)) {
          await api.plannings.deleteActivity(this.project.id, directionId);
        }
        return true;
      } catch (e) {
        console.error("deleteDirectionDB", e.message);
        if (e.result.error_code === "DeleteAssignmentSubAssignmentError") {
          return {action: "reload"};
        }
        return false;
      }
    },
    async setEmployeeDB({direction, employee, method, changed}) {
      try {
        if (isFake(employee.profile.id)) {
          return {status: true};
        }
        const leadId = direction.leadId;
        const employeeId = employee.profile.id;
        const plan_hours = employee.plan_hours.value;

        const PARAMS_LIB = {
          ...(typeof plan_hours === "number" && {plan_hours}),
          profile: employeeId,
          lead: leadId === employeeId,
        };

        const editedParam = {
          [changed]: PARAMS_LIB[changed],
        };
        if (method === "PATCH") {
          try {
            await api.plannings.changeSubactivity(
                this.project.id,
                direction.id,
                employee.id,
                editedParam
            );
            this.setResultChangePlanningHours(employee, "ok");
          } catch (e) {
            this.$pushMessage(
                {
                  title: `${e.code} Изменение поднаправления на проекте`,
                  message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
                },
                {root: true}
            );
            this.setResultChangePlanningHours(employee, "error");
          }
        } else {
          await api.plannings.createSubactivity(
              this.project.id,
              direction.id,
              PARAMS_LIB
          );
        }
        return {status: true};
      } catch (e) {
        console.error("setEmployeeDB", e);
        return {status: false, errorCode: e.error_code};
      }
    },
    async setPlanHours(event, directionId, employeeIndex) {
      const dirIdx = this.getDirIndexById(directionId);
      const direction = this.directions[dirIdx];
      const employee = direction.employees[employeeIndex];
      try {
        if (event.key === "Enter") {
          event.target.blur();
        }
        if (event.type === "blur") {
          if (isFake(employee.id)) {
            this.$pushMessage({
              message: "Сначала выберите (назначьте) сотрудника",
              title: "Указание планируемых часов",
              withoutDelay: true,
            });
            employee.update = Date.now();
            employee.plan_hours.value = null;
          } else {
            const inputValue = parseInt(event.target.value || 0);
            employee.plan_hours.value = inputValue;
            await this.setEmployee({
              directionId,
              employeeIndex,
              changed: "plan_hours",
            });

            this.setLastUpdate();
          }
        }
      } catch (e) {
        employee.plan_hours.isReady = false;
        console.error("setPlanHours", e);
        return false;
      }
    },
    setResultChangePlanningHours(employee, result) {
      employee.plan_hours.resultServerRequest = result;
      setTimeout(() => {
        employee.plan_hours.resultServerRequest = null;
      }, 3000);
    },
    setSelectedEmployeesId(direction) {
      this.$set(this.selectedEmployees, direction.activity.id, []);
      const selectedEmployees = this.selectedEmployees[direction.activity.id];
      direction.sub_assignments.forEach((employee) => {
        selectedEmployees.push(employee.profile.id);
      });
    },
    async deleteEmployeeDB(directionId, employeeId) {
      try {
        await api.plannings.deleteSubactivity(
            this.project.id,
            directionId,
            employeeId
        );
        return true;
      } catch (e) {
        console.error("deleteEmployeeDB", e);
        return false;
      }
    },
    async createDirection() {
      const newDirection = this.directionPrototype();
      this.directions.push(newDirection);
      this.sortDirections();
    },
    async removeDirection(directionId) {
      const dirIdx = this.getDirIndexById(directionId);
      this.directions[dirIdx].isReady = false;
      const remoteActivityId = this.directions[dirIdx].activity.id;
      this.selectedDirectionControl(null, remoteActivityId);
      this.selectedEmployeesControl({
        action: "clearList",
        directionId: remoteActivityId,
      });
      const isRemoved = await this.deleteDirectionDB(directionId);
      if (!isRemoved) {
        this.$pushMessage({
          message: "Действие запрещено/редактирование запрещено",
          title: "Удаление направления",
        });
        this.directions[dirIdx].isReady = true;
        return false;
      }
      this.directions.splice(dirIdx, 1);
      this.setTotalProjectTime();
      this.setLastUpdate();
      await this.getDirectionsNamesDB();
    },
    sortDirections() {
      this.directions.sort(
          (a, b) => a.activity.role_sort - b.activity.role_sort
      );
    },
    selectedDirectionControl(setDirectionId, lastDirectionId) {
      const store = this.selectedDirectionsIds;
      const dirIdx = store.findIndex((id) => id === lastDirectionId);
      if (dirIdx >= 0) {
        store.splice(dirIdx, 1);
      }
      if (!isFake(setDirectionId) && !store?.includes(setDirectionId)) {
        store.push(setDirectionId);
      }
    },
    selectedEmployeesControl({
                               action,
                               directionId,
                               setEmployeeId,
                               removeEmployeeId,
                             }) {
      const actions = ["replace", "delete", "clearList"];
      const employeesInDirection = this.selectedEmployees[directionId];
      if (!actions.includes(action)) {
        throw new Error("Указан неверный 'action'");
      }
      if (isFake(directionId) && isFake(setEmployeeId)) {
        return;
      }
      if (!employeesInDirection) {
        return;
      }
      if (action === "clearList") {
        employeesInDirection.splice(0);
        return true;
      }
      const deletedIdIdx = employeesInDirection.findIndex(
          (id) => id === removeEmployeeId
      );
      if (deletedIdIdx >= 0) {
        employeesInDirection.splice(deletedIdIdx, 1);
      }
      if (setEmployeeId && !employeesInDirection.includes(setEmployeeId)) {
        employeesInDirection.push(setEmployeeId);
      }
    },
    async selectDirectionName(dirTitle, {lastDirectionId}) {
      const dirIdx = this.getDirIndexById(lastDirectionId);
      const lastActivityId = this.directions[dirIdx].activity.id;
      // если есть сотрудники из другого направления предупреждаем о их удалении
      const hasTrueEmployee = this.directions[dirIdx].employees.find(
          (employee) => !isFake(employee.profile.id)
      );
      if (hasTrueEmployee) {
        this.$pushMessage({
          message: "Действие запрещено/редактирование запрещено",
          title: "Смена направления",
        });
        // устанавливаем новое значение (для key), чтобы компонент Select выводил текущее направление
        this.directions[dirIdx].update = Date.now().toString();
        return;
      }

      if (!this.selectedEmployees[dirTitle.id]) {
        this.$set(this.selectedEmployees, dirTitle.id, []);
      }
      this.selectedDirectionControl(dirTitle.id, lastActivityId);
      this.directions[dirIdx].isReady = false;
      const dirNameIdx = this.getDirNameIndexById(dirTitle.id);
      this.directionsNames.splice(dirNameIdx, 1);
      await this.setDirectionDB(dirTitle.id);
      await this.deleteDirectionDB(lastDirectionId);
      const newDirection = await this.getDirectionInfoDB(dirTitle.id);
      this.setDirection(newDirection, dirIdx);
      if (!isFake(dirTitle.id)) {
        this.setStaffListForDirection(dirTitle.id);
      }
      this.directions[dirIdx].isReady = true;
      this.sortDirections();
      // загружаем обновленный список направлений
      await this.getDirectionsNamesDB();
    },
    setDirection(directionData, directionIndex, changedEmployeeId) {
      if (!directionData) {
        return;
      }
      const {activity, id, sub_assignments, total_plan_hours} = directionData;
      const updateTime = Date.now().toString();
      const employees = [];
      const employeesLastList = this.directions[directionIndex]?.employees;
      const setSubdirectionIds = [];

      if (sub_assignments.length > 0) {
        sub_assignments.forEach((employee) => {
          setSubdirectionIds.push(employee.id);
          employees.push({
            ...employee,
            update: updateTime,
            isReady: true,
            plan_hours: {
              value: employee.plan_hours,
              isReady: true,
              resultServerRequest: null,
            },
          });
        });
      }

      if (employeesLastList && employees.length < employeesLastList.length) {
        employeesLastList.forEach((employee) => {
          if (
              setSubdirectionIds?.includes(employee.id) ||
              changedEmployeeId === employee.id
          ) {
            return;
          }
          employees.push(employee);
        });
      }
      if (!employees.length) {
        employees.push(this.employeePrototype());
      }

      const leadId = (employees.find((employee) => employee.lead) || {})
          ?.profile?.id;

      const updatedDirection = {
        activity,
        id,
        total_plan_hours,
        employees,
        leadId: leadId || null,
        isReady: true,
        update: updateTime,
      };

      if (typeof directionIndex === "number") {
        this.$set(
            this.directions,
            directionIndex,
            Object.assign({}, this.directions[directionIndex], updatedDirection)
        );
      } else {
        const dirIdx = this.directions.findIndex((dir) => dir.id === id);
        if (dirIdx > -1) {
          this.directions.splice(dirIdx, 1);
        }
        this.directions.push(updatedDirection);
      }
      this.sortEmployees(id);
    },
    getDirIndexById(id) {
      return this.directions.findIndex((item) => item.id === id);
    },
    getDirNameIndexById(id) {
      return this.directionsNames.findIndex((item) => item.id === id);
    },
    createEmployee(dirId) {
      const dirIdx = this.getDirIndexById(dirId);
      this.directions[dirIdx].employees.push(this.employeePrototype());
      this.sortEmployees(dirId);
    },
    async selectEmployee(selectedEmployee, data) {
      const {direction, employeeIdx} = data;
      this.searchName = "";
      const dirIdx = this.getDirIndexById(direction.id);
      const subdirection = this.directions[dirIdx].employees[employeeIdx];
      try {
        subdirection.isReady = false;
        const lastEmployeeId = direction.employees[employeeIdx].id;
        const convertedEmployee = this.employeePrototype(
            subdirection,
            selectedEmployee
        );
        const methodName = isFake(convertedEmployee.id) ? "POST" : "PATCH";
        const isSet = (
            await this.setEmployeeDB({
              direction,
              employee: convertedEmployee,
              method: methodName,
              changed: "profile",
            })
        ).status;
        if (!isSet) {
          this.$pushMessage({
            message: "Действие запрещено/редактирование запрещено",
            title: "Смена сотрудника",
          });
          this.directions[dirIdx].employees[employeeIdx].update = Date.now();
          this.directions[dirIdx].employees[employeeIdx].isReady = true;
          return;
        }
        this.selectedEmployeesControl({
          action: "replace",
          directionId: direction?.activity?.id,
          setEmployeeId: selectedEmployee?.id,
          removeEmployeeId: subdirection?.profile?.id,
        });

        this.setLastUpdate();
        const newDirection = await this.getDirectionInfoDB(
            direction.activity.id
        );
        this.setDirection(newDirection, dirIdx, lastEmployeeId);
        this.directions[dirIdx].employees[employeeIdx].isReady = true;
        this.sortEmployees(direction.id);
        this.setTotalProjectTime();
      } catch (e) {
        this.directions[dirIdx].employees[employeeIdx].isReady = false;
        console.error("selectEmployee", e);
        return false;
      }
    },
    employeeSelectInfo(direction) {
      if (!direction.isReady) {
        this.$pushMessage({
          message: "Дождитесь окончания загрузки",
          title: "Выбор сотрудника",
        });
      } else if (!direction?.activity.activity) {
        this.$pushMessage({
          message: "Сначала выберите (назначьте) направление",
          title: "Выбор сотрудника",
        });
      }
    },
    sortEmployees(dirId) {
      const dirIndex = this.getDirIndexById(dirId);
      const directionLeadId = this.directions[dirIndex].leadId;
      const ORDER = {
        JUNIOR: 1,
        MIDDLE: 2,
        SENIOR: 3,
        LEAD: 4,
      };

      this.directions[dirIndex].employees.sort((a, b) => {
        return (
            (b.profile.id === directionLeadId) -
            (a.profile.id === directionLeadId) ||
            ORDER[b.profile.qualification] - ORDER[a.profile.qualification] ||
            (a.profile.last_name > b.profile.last_name) -
            (b.profile.last_name > a.profile.last_name) ||
            (a.profile.first_name > b.profile.first_name) -
            (b.profile.first_name > a.profile.first_name)
        );
      });
    },
    async removeEmployee(dirId, employee) {
      try {
        const directionIdx = this.getDirIndexById(dirId);
        const employeeIdx = this.getEmployeeIndexInDirById(
            dirId,
            employee.profile.id
        );
        const currentDirection = this.directions[directionIdx];

        if (!currentDirection) {
          return;
        }

        currentDirection.isReady = false;
        this.selectedEmployeesControl({
          action: "delete",
          directionId: currentDirection?.activity.id,
          removeEmployeeId: employee.profile.id,
        });

        const employees = currentDirection.employees;
        const trueEmployees = currentDirection.employees.filter((employee) => {
          return !isFake(employee.id);
        });

        if (
            employees.length === 1 ||
            (trueEmployees.length === 1 &&
                employees.length > 1 &&
                employee.profile.id === trueEmployees[0].profile.id)
        ) {
          if (employees.length === 1 && isFake(employee.id)) {
            const isRemovedEmployee = await this.deleteDirectionDB(dirId);
            if (!isRemovedEmployee) {
              this.$pushMessage({
                title: "Удаление направления",
                message: "Действие запрещено",
              });
              currentDirection.isReady = true;
              return false;
            }
          }
          if (employee.lead) {
            currentDirection.leadId = null;
          }
          this.selectedDirectionControl(null, currentDirection?.activity.id);
          this.directions.splice(directionIdx, 1);
          await this.getDirectionsNamesDB();
          return;
        }

        if (employee.lead) {
          currentDirection.leadId = null;
        }

        currentDirection.employees.splice(employeeIdx, 1);
        this.sortEmployees(dirId);
        this.setLastUpdate();
        const newDirection = await this.getDirectionInfoDB(
            currentDirection?.activity.id
        );
        this.setDirection(newDirection, directionIdx);
        currentDirection.isReady = true;
      } catch (e) {
        console.error("removeEmployee", e);
        return false;
      }
    },
    getEmployeeIndexInDirById(dirId, employeeId) {
      const dirIdx = this.getDirIndexById(dirId);
      return this.directions[dirIdx].employees.findIndex(
          (employee) => employee.profile.id === employeeId
      );
    },
    getEmployeeName(employee) {
      if (!employee) {
        return;
      }
      const keys = [
        employee.last_name,
        employee.first_name,
        employee.qualification
            ? `(${employee.qualification.toLowerCase()}${
                employee.english_level
                    ? `, ${EN_LEVELS[employee.english_level]}`
                    : ""
            })`
            : "",
      ].filter((item) => item);
      return keys.join(" ");
    },
    checkInfo(employeeId) {
      if (isFake(employeeId)) {
        this.$pushMessage({
          message: "Сначала выберите (назначьте) сотрудника",
          title: "Выбор лида",
        });
      }
    },
    checkLead(dirId, employeeId) {
      if (isFake(employeeId)) {
        return;
      }
      const dirIdx = this.getDirIndexById(dirId);
      const employeeIdx = this.getEmployeeIndexInDirById(dirId, employeeId);
      const lastLeadId = this.directions[dirIdx].leadId;
      const currentEmployee = this.directions[dirIdx].employees[employeeIdx];
      // убираем лидера при повторном клике
      if (currentEmployee.profile.id === lastLeadId) {
        this.directions[dirIdx].leadId = null;
      } else {
        this.directions[dirIdx].leadId = employeeId;
      }
    },
    async setLead(directionId, employeeIndex, employeeId) {
      try {
        if (!isFake(employeeId)) {
          await this.setEmployee({
            directionId,
            employeeIndex,
            changed: "lead",
          });
        }
      } catch (e) {
        const dirIdx = this.getDirIndexById(directionId);
        this.directions[dirIdx].employees[employeeIndex].isReady = false;
        console.error("setLeadDB", e);
        return false;
      }
    },
    async setEmployee({directionId, employeeIndex, changed}) {
      try {
        const dirIdx = this.getDirIndexById(directionId);
        const direction = this.directions[dirIdx];
        const employee = direction.employees[employeeIndex];
        employee.isReady = false;
        const result = await this.setEmployeeDB({
          direction,
          employee,
          changed,
          method: "PATCH",
        });
        const newDirection = await this.getDirectionInfoDB(
            direction.activity.id
        );
        this.setDirection(newDirection, dirIdx);
        employee.isReady = result.status;
        this.sortEmployees(direction.id);
        this.setTotalProjectTime();
        if (!result.status && result.errorCode === "validation_error") {
          this.$pushMessage({
            message: "Действие запрещено/редактирование запрещено",
            title: "Указание планируемых часов",
          });
        }
        return true;
      } catch (e) {
        console.error("setEmployee", e);
        return false;
      }
    },
    inputValidation(event) {
      const input = event.target;
      if (input.value > 744) {
        // 744 - это 24 часа * 31 день, больше работать не получится
        input.value = 744;
      }
    },
    setProjectStack() {
      let stackString = "";
      const stack = this.project.tech_stacks;
      const stackLength = stack.length;
      stack.forEach((item, idx) => {
        stackString += item.technology_name;
        if (idx < stackLength - 1) {
          stackString += ", ";
        }
      });
      this.projectStack = stackString;
    },
    setTotalProjectTime() {
      let count = 0;
      this.directions.forEach((direction) => {
        count += direction.total_plan_hours;
      });
      this.totalProjectTime = count;
    },
    focusInput(id) {
      this.$refs[id]?.[0].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.project-table {
  display: grid;
  grid-template-rows:
    "title"
    "head"
    "body"
    "footer";
  width: min-content;
  padding: 10px;
  padding-top: 0px;
  border-radius: 7px;
  background: linear-gradient(
          $erp-primary-bg-color 85px,
          #f3f3f3 85px,
          #f3f3f3 calc(100% - 40px),
          $erp-primary-bg-color calc(100% - 40px)
  );
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  user-select: none;
  font-size: 12px;
}

.project-table--collapsed {
  padding-bottom: 0;
}

.project-table:not(:last-child) {
  margin-bottom: 30px;
}

.project-table__title {
  background-color: $erp-primary-bg-color;
  color: #f3f3f3;
  padding-bottom: 10px;
  cursor: pointer;

  .project-table--collapsed & {
    width: 830px;
    padding-bottom: 0;
  }
}

.project-table__name,
.project-table__stack {
  font-weight: 700;
  text-align: center;
  padding: 4px 0;
}

.project-table__head {
  display: grid;
  grid-template-columns: 180px 120px 180px 120px 230px;
  background-color: #f3f3f3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 10px;
  font-weight: 700;

  .project-table--collapsed & {
    display: none;
  }
}

.project-table__head-column {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.project-table__head-column:not(:last-child) {
  border-right: 1px solid #808080;
}

.project-table__head-column--with-subcolumns {
  flex-direction: column;
  padding-bottom: 0px;
}

.project-table__head-column-title {
  padding-bottom: 8px;
}

.project-table__head-column-subcolumns {
  display: flex;
  width: 100%;
}

.project-table__head-column-subtitle {
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;
  border-top: 1px solid #808080;
  width: 50%;
}

.project-table__head-column-subtitle:first-child {
  border-right: 1px solid #808080;
}

.project-table__body {
  background-color: #f3f3f3;

  .project-table--collapsed & {
    display: none;
  }
}

.project-table__body-direction:not(:first-child) {
  margin-top: 10px;
}

.project-table__body-direction {
  padding: 10px 0;
  display: grid;
  grid-template-columns: 180px 120px 1fr;
  box-shadow: 2px 2px 8px 2px rgba(34, 60, 80, 0.2);
  border-radius: 5px;
}

.project-table__body-direction-column {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.direction-name {
  grid-template-columns: 1fr max-content max-content;
}

.subdirection-employee {
  grid-template-columns: max-content 1fr max-content;
  padding: 0 8px;
}

.project-table__radio {
  margin-right: 5px;
}

.project-table__input {
  width: 40%;
  border: none;
  text-align: center;
  background-color: inherit;
  font-family: inherit;
  margin: 0 auto;
  font-weight: inherit;

  &--error {
    color: #d87474;
  }

  &--ok {
    color: #82d688;
  }
}

.project-table__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.project-table__body-subdirections {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-table__body-subdirection {
  display: grid;
  grid-template-columns: 180px 120px 230px;
  padding: 5px 0;
}

.project-table__body-subdirection--lead {
  font-weight: 600;
}

.project-table__body-subdirection:first-child {
  padding-top: 0px;
}

.project-table__body-subdirection:last-child {
  padding-bottom: 0px;
}

.project-table__body-subdirection:not(:last-child) {
  border-bottom: 1px dashed #d3d3d3;
}

.project-table__body-subdirection-subcolumns {
  grid-template-columns: 1fr 1fr;
}

.project-table__footer {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 10px;

  .project-table--collapsed & {
    display: none;
  }
}

.project-table__footer-create,
.project-table__table__footer-info {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 6px 12px;
  background-color: #f3f3f3;
  box-shadow: 2px 2px 8px 2px rgba(34, 60, 80, 0.2);
  width: max-content;
}

.project-table__footer-create {
  cursor: pointer;
  transition: background-color 350ms;
  border: 2px solid $erp-primary-bg-color;
}

.project-table__footer-create:hover {
  background-color: #faebd7;
}

.project-table__footer-create--disabled {
  border: 2px solid #808080;
  color: #808080;
  pointer-events: none;
}

.button-icon {
  & svg {
    width: 12px;
    height: 12px;
  }

  & img {
    width: 12px;
    height: 12px;
  }
}

.project-table__actions {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.project-table__action {
  width: 20px;
  height: 20px;
  margin: 0 4px;
}

.project-table__tooltip {
  cursor: pointer;
}

.project-table__tooltip-message {
  text-align: left;
  border-radius: 6px;
  border: 1px solid #000000;
  background: #ffffff;
  font-weight: 400;
  cursor: default;
}

.project-table__tooltip-message-item {
  padding: 4px 10px;

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
}

.attention-bg--ok {
  background-color: #dcf7c7;
}

.attention-bg--oversee {
  background-color: #f4d3a2;
}

.attention-bg--worry {
  background-color: #f9b398;
}
</style>
