var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",staticClass:"change-planning-modal",attrs:{"data":{
    hasOverlay: true,
    title: `${
      _vm.getIsChangeEmployeeMonthsPlanningsListModeAdd ? 'Добавить' : 'Удалить'
    } сотрудника`,
    actions: _vm.actionsData,
  }},on:{"close":_vm.clearError}},[_c('div',{staticClass:"change-planning-modal__list"},_vm._l((_vm.getAvailableEmployeeMonthsPlanningsList),function(availableMonth,index){return _c('div',{key:index,staticClass:"change-planning-modal__item"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isMonthChecked(availableMonth.id)},on:{"click":function($event){return _vm.monthClickHandler(availableMonth.id)}}}),_c('div',{staticClass:"change-planning-modal__month--info"},[_vm._v(" "+_vm._s(`${_vm.formatMonthNumber(availableMonth)} ${availableMonth.year.number}`)+" ")]),(availableMonth.id === _vm.monthWithError)?_c('div',{staticClass:"change-planning-modal__item--error"},[_vm._v(" планируемые часы меньше, чем актуальные ")]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }