<template>
  <Modal
    ref="modal"
    class="change-planning-modal"
    :data="{
      hasOverlay: true,
      title: `${
        getIsChangeEmployeeMonthsPlanningsListModeAdd ? 'Добавить' : 'Удалить'
      } сотрудника`,
      actions: actionsData,
    }"
    @close="clearError"
  >
    <div class="change-planning-modal__list">
      <div
        :key="index"
        v-for="(
          availableMonth, index
        ) of getAvailableEmployeeMonthsPlanningsList"
        class="change-planning-modal__item"
      >
        <input
          type="checkbox"
          :checked="isMonthChecked(availableMonth.id)"
          @click="monthClickHandler(availableMonth.id)"
        />
        <div class="change-planning-modal__month--info">
          {{
            `${formatMonthNumber(availableMonth)} ${availableMonth.year.number}`
          }}
        </div>
        <div
          v-if="availableMonth.id === monthWithError"
          class="change-planning-modal__item--error"
        >
          планируемые часы меньше, чем актуальные
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Modal from "./ModalProto";
import dayjs from "dayjs";
import "dayjs/locale/ru";

export default {
  name: "ChangePlanningModal",
  components: {
    Modal,
  },
  data() {
    return {
      checkedMonthIds: [],
      monthWithError: "",
      actionsData: [
        {
          title: "выбрать всё",
          id: "",
          handler: () => {
            this.checkedMonthIds =
              this.getAvailableEmployeeMonthsPlanningsList.map(
                (availableMonth) => availableMonth.id
              );
          },
        },
        {
          title: "отменить всё",
          id: "",
          handler: () => {
            this.checkedMonthIds = [];
          },
        },
        {
          title: "ок",
          id: "accept",
          handler: async () => {
            const result =
              await this.changeAvailableEmployeeMonthsPlanningsList({
                sub_assignment_id: this.getCurrentEmployeeMonthsPlanningsListId,
                isDeletion: !this.getIsChangeEmployeeMonthsPlanningsListModeAdd,
                month_ids: this.checkedMonthIds,
              });

            if (
              this.checkedMonthIds.find(
                (monthId) =>
                  monthId ===
                  this.getCurrentMonthForAvailableMonthsPlanningsList
              )
            ) {
              this.setLastUpdate();
            }

            if (result.code === 400) {
              this.monthWithError = parseInt(result.details.month);
            } else {
              this.$refs.modal.close();
            }
          },
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setLastUpdate: "planningsStore/setLastUpdate",
    }),
    ...mapActions({
      changeAvailableEmployeeMonthsPlanningsList:
        "planningsStore/changeAvailableEmployeeMonthsPlanningsList",
    }),
    clearError() {
      this.monthWithError = "";
    },
    isMonthChecked(availableMonthId) {
      return this.checkedMonthIds.find(
        (checkedMonthId) => checkedMonthId === availableMonthId
      );
    },
    formatMonthNumber(availableMonth) {
      return dayjs(
        `${availableMonth.year.number}-${availableMonth.name}`,
        "YYYY-M"
      )
        .locale("ru")
        .format("MMMM");
    },
    monthClickHandler(availableMonthId) {
      if (
        this.checkedMonthIds.find(
          (checkedMonthId) => checkedMonthId === availableMonthId
        )
      ) {
        this.checkedMonthIds = this.checkedMonthIds.filter(
          (checkedMonthId) => checkedMonthId !== availableMonthId
        );
      } else {
        this.checkedMonthIds.push(availableMonthId);
      }
    },
  },
  computed: {
    ...mapGetters({
      getAvailableEmployeeMonthsPlanningsList:
        "planningsStore/getAvailableEmployeeMonthsPlanningsList",
      getIsChangeEmployeeMonthsPlanningsListModeAdd:
        "planningsStore/getIsChangeEmployeeMonthsPlanningsListModeAdd",
      getCurrentEmployeeMonthsPlanningsListId:
        "planningsStore/getCurrentEmployeeMonthsPlanningsListId",
      getCurrentMonthForAvailableMonthsPlanningsList:
        "planningsStore/getCurrentMonthForAvailableMonthsPlanningsList",
    }),
  },
  watch: {
    getAvailableEmployeeMonthsPlanningsList: {
      handler(availableEmployeeMonthsPlanningsList) {
        if (availableEmployeeMonthsPlanningsList.length) {
          this.checkedMonthIds = availableEmployeeMonthsPlanningsList.map(
            (availableMonth) => availableMonth.id
          );
          this.$refs?.modal.open();
          this.$refs?.modal.setPosition("center");
        } else {
          this.$refs?.modal.close();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";

.change-planning-modal {
  position: fixed;
}

.change-planning-modal__list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.change-planning-modal__item {
  display: flex;
  align-items: center;
  column-gap: 7px;
}

.change-planning-modal__month--info {
}

.change-planning-modal__item--error {
  font-size: 12px;
  color: var(--error-color);
}
</style>
