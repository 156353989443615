<template>
  <Modal
    :data="{ hasOverlay: true, title: 'Выберите пункт меню', actions: [] }"
    class="modal__context-menu"
    ref="modal"
    v-click-outside="close"
  >
    <div class="context-menu__list">
      <template v-for="(menuItem, index) in content.menuList">
        <div
          :key="index"
          class="context-menu__item"
          :class="{ 'context-menu__item--disabled': menuItem.isDisabled }"
          @click="!menuItem.isDisabled && menuClick(menuItem.action)"
        >
          {{ menuItem.title }}
        </div>
      </template>
    </div>
  </Modal>
</template>

<script>
import Modal from "./ModalProto";

export default {
  name: "ContextMenuModal",
  components: {
    Modal,
  },
  data() {
    return {
      content: {
        menuList: [
          {
            title: "название пункта меню",
            action: () => {
              console.log("нажатие на пункт меню");
            },
          },
        ],
      },
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    setPosition(coords) {
      if (!coords) {
        return;
      }
      this.$refs.modal.setPosition(coords);
    },
    setModalContent(modalContent) {
      this.content.menuList = modalContent;
    },
    async menuClick(action) {
      if (!action) {
        console.warn("обработчик нажатия на пункт меню не может быть пустым");
        return;
      }

      this.close();
      await action();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/modal.scss";

.modal__context-menu {
  position: fixed;
}

.context-menu__list {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.context-menu__item {
  padding: 5px 2px;
  font-size: 14px;
}

.context-menu__item:hover {
  cursor: pointer;
  background-color: var(--hover-color);
  border-radius: 6px;
}

.context-menu__item--disabled {
  background-color: var(--non-editable-bg-color);
  color: var(--disabled-color);
}

.context-menu__item--disabled:hover {
  cursor: default;
  background-color: var(--non-editable-bg-color);
  border-radius: 0;
}
</style>
