var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"planning-month__staff-list"},[_vm._l((_vm.activities),function(activity){return [(activity.staff.length)?_c('div',{key:activity.id,staticClass:"staff-info-table",class:{
        'staff-info-table--collapsed': _vm.viewState[activity.id] === 'collapsed',
      }},[_c('div',{staticClass:"staff-info-table__title",on:{"click":function($event){return _vm.toggleView(activity.id)}}},[_vm._v(" "+_vm._s(activity.role)+" ")]),_vm._m(0,true),_c('div',{staticClass:"staff-info-table__body"},_vm._l((activity.staff),function(staff){return _c('div',{key:staff.id,staticClass:"staff-info-table__body-item columns-template"},[_c('div',{staticClass:"staff-info-table__body-column"},[_vm._v(" "+_vm._s(_vm.fullName(staff))+" ")]),_c('div',{staticClass:"staff-info-table__body-column"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(staff.qualification))+" ")]),_c('div',{staticClass:"staff-info-table__body-column"},[_vm._v(" "+_vm._s(_vm.EN_LEVELS[staff.english_level])+" ")]),_c('div',{staticClass:"staff-info-table__body-column"},[_c('span',{staticClass:"column__value"},[_vm._v(_vm._s(staff.total_projects))]),_c('span',{staticClass:"column__attention-bg",class:{
                'column__attention-bg--worry': staff.total_projects > 2,
                'column__attention-bg--oversee': staff.total_projects === 2,
              }})]),_c('div',{staticClass:"staff-info-table__body-column"},[_vm._v(" "+_vm._s(_vm.getAvailableHours(staff))+" ")]),_c('div',{staticClass:"staff-info-table__body-column"},[_vm._v(" "+_vm._s(staff.plan_hours)+" ")]),_c('div',{staticClass:"staff-info-table__body-column"},[_c('span',{staticClass:"column__value"},[_vm._v(_vm._s(staff.dif))]),_c('span',{staticClass:"column__attention-bg",class:{
                'column__attention-bg--ok': staff.dif <= 5 && staff.dif >= -5,
                'column__attention-bg--worry': staff.dif > 5,
                'column__attention-bg--oversee': staff.dif < -5,
              }})]),_c('StaffTableCommentCell',{attrs:{"data":staff.employee_month[0]},on:{"save":_vm.updateComment}})],1)}),0)]):_vm._e()]})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staff-info-table__head columns-template"},[_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Сотрудник")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Квалификация")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("En level")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v(" Кол-во производ. проектов ")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Доступность")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Запланировано (всего)")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Расхождение")]),_c('div',{staticClass:"staff-info-table__head-column"},[_vm._v("Комментарий")])])
}]

export { render, staticRenderFns }