<template>
  <span
    @click="clickRadio"
    class="radio-button"
    :class="{ 'radio-button--disabled': disabled }"
    ><span v-show="isLead" class="radio-button--checked"></span
  ></span>
</template>

<script>
export default {
  name: "erm-radio-button",
  props: {
    isLead: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickRadio() {
      this.$emit("clickRadio");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

input {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  user-select: none;
}

.radio-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: inherit;
  border: 1px solid $erp-primary-bg-color;
  border-radius: 2px;
  cursor: pointer;
}

.radio-button--disabled {
  pointer-events: none;
  border-color: $erp-disabled-color;
}

.radio-button--checked {
  display: block;
  width: 6px;
  height: 6px;
  background-color: $erp-primary-bg-color;
  .radio-button--disabled & {
    background-color: $erp-disabled-color;
  }
}
</style>
